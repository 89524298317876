import {ISiteTimeseriesResult} from 'domain/Portfolio'
import useNewChartColors from 'hooks/useNewChartColors'
import React from 'react'
import {autoCeilNumber, autoFloorNumber} from '../../../../helpers/misc'
import Chart, {ChartType, getMaxValue, getMinValue, ILine, StackType} from '../../../Chart'

interface IProps extends React.PropsWithChildren {
  timeseries: ISiteTimeseriesResult
  siteName: string
}

const SiteDashboardChart: React.FC<IProps> = ({timeseries, siteName}) => {
  const chartColors = useNewChartColors()

  if (!timeseries) {
    return null
  }
  const lines: ILine[] = []

  const labels = timeseries.timeseries.map(({name}) => name)

  lines.push({
    key: `${timeseries.siteId}`,
    label: siteName,
    color: chartColors.getNext(),
    data: timeseries.timeseries.map(({value}) => value),
    type: ChartType.LINE,
    stackType: StackType.STACKED,
    fill: true,
  })

  return (
    <Chart
      toolbar={{vertical: false, timescale: {hideYearly: true, hideMonthly: true}}}
      labels={labels}
      lines={lines}
      dark
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
    />
  )
}

export default SiteDashboardChart
