import {buyerSiteTimeseriesAtom, dateFilterAtom} from 'atoms/portfolioSites'
import Box from 'components/Box'
import InnerScroll from 'components/InnerScroll'
import PeriodSelect from 'components/PeriodSelect'
import ISite from 'domain/ISite'
import {useAtomValue, useSetAtom} from 'jotai'
import React from 'react'
import SiteDashboardChart from '../BuyerSiteDashboard'
import CenteredText from 'components/CenterText'
import useLocalization from 'hooks/useLocalization'

interface IProps extends React.PropsWithChildren {
  site: ISite
}

const BuyerProfile: React.FC<IProps> = ({site}) => {
  const {data: timeseries} = useAtomValue(buyerSiteTimeseriesAtom)
  const setDateFilter = useSetAtom(dateFilterAtom)
  const {translate} = useLocalization()
  if (!timeseries) {
    return <CenteredText text={translate('No timeseries data available')} />
  }

  return (
    <Box>
      <InnerScroll noRightPad>
        <PeriodSelect hideCustom hideQuarter hideWholeYear setValue={setDateFilter} />
        <Box pad={{top: 2}}>
          <SiteDashboardChart timeseries={timeseries} siteName={site.name} />
        </Box>
      </InnerScroll>
    </Box>
  )
}

export default BuyerProfile
